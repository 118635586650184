import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import axios from "axios";
import Select2 from "vue3-select2-component";

//
// const axiosInstance = axios.create({
//   baseURL: "http://localhost:8080",
// });

const axiosInstance = axios.create({
  baseURL: "https://hshbackendv2-api-dev.azurewebsites.net",
});

// const axiosInstance = axios.create({
//   baseURL: "https://hshbackendv2-api-pre-prod.azurewebsites.net",
// });
// const axiosInstance = axios.create({
//   baseURL: "https://stay-dev-api.hosafarms.com",
// });

// const axiosInstance = axios.create({
//   baseURL: "https://stay-api.hosafarms.com",
// });

axiosInstance.interceptors.request.use(
  (request) => {
    if (localStorage.getItem("token")) {
      request.headers["Authorization"] =
        localStorage.getItem("token");
      return request;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.request.use(
  function (config) {
    config.headers["Authorization"] = store.getters.getToken;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

let app = createApp(App);

app.use(store);
app.use(router);

app.use(VueSweetalert2);
app.component("Select2", Select2);

app.config.globalProperties.$axios = { ...axiosInstance };

app.mount("#app");
