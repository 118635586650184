<template>
  <main>
    <section>
      <div class="container mb-10">
            <div>
              <div class="row text-center mt-5 mb-4">
                <div class="col-md-12">
                  <h4 class="fw-semibold">Upload Photos</h4>
                </div>
              </div>
              <div class="section-form">
                <div class="">
                  <div
                    class="upload-area__drop-zoon drop-zoon"
                    v-bind="getRootProps()"
                  >
                    <input
                      id="fileInput"
                      class="drop-zoon__file-input"
                      accept="image/*"
                      v-bind="getInputProps()"
                    />
                    <span class="drop-zoon__icon">
                      <i class="fa fa-file-image-o" aria-hidden="true"></i>
                    </span>
                    <p v-if="isDragActive">Drop the files here ...</p>
                    <p v-else>
                      Drag 'n' drop some files here, or click to select files
                    </p>
                  </div>
                </div>
                <div></div>
              </div>
              <div class="section-form2">
                <div class="row">
                  <div
                    class="col-md-6 mb-2"
                    v-for="(image, i) in form.images"
                    :key="i"
                  >
                    <div
                      class="bg-image hover-overlay ripple shadow-1-strong rounded position-relative"
                      style="width: 300px; height: 200px"
                    >
                      <img :src="(image.url)? image.url : image.location" class="w-100 h-100 rounded" />
                      <button
                        class="upload_btn border-0 rounded-circle"
                        @click="toggle[i] = !toggle[i]"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-three-dots-vertical"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                          />
                        </svg>
                      </button>
                      <ul
                        class="list-unstyled font-size-1 w-50 p-1 rounded upload_li"
                        v-show="toggle[i]"
                      >
                        <li
                          class="border-bottom p-1"
                          @click="MarkAsCoverImage(i)"
                        >
                          Make cover photo
                        </li>
                        <li class="p-1" @click="deleteImage(i)">Delete</li>
                      </ul>
                     
                      <span
                        class="badge text-black upload_span"
                        v-if="image.isCoverImage"
                        >Cover photo</span
                      >
                    </div>
                  </div>

                  <!-- <div class="col-md-2" v-for="(image, i) in form.images" :key="i">
                                        <img :src="image.url" width="200" height="200">
                                        <div class="d-grid gap-2">
                                            <button href="#" class="btn btn-sm " :class="{
                                                'btn-primary': !image.isCoverImage,
                                                'btn-success': image.isCoverImage
                                            }" @click="MarkAsCoverImage(i)">
                                                <i class="fa fa-star" aria-hidden="true"
                                                    v-if="image.isCoverImage == true"></i>
                                                <i class="fa fa-star-o" aria-hidden="true" v-else></i> Cover image
                                            </button>
                                        </div>
                                    </div> -->
                </div>
              </div>
            </div>
          </div>
    </section>
    <footer>
          <div class="fixed-bottom bg-white">
            <hr class="mt-0" />
            <div class="row">
              <div class="col-md-6 mb-3 ps-5">
                <a
                  type="button"
                  class="btn bg-gray fw-light px-4 border me-4"
                  @click="$router.go(-1)"
                  >Back</a
                >
              </div>
              <div class="col-md-6 text-end mb-3 pe-5">
                <button
                  type="button"
                
                  @click="saveButton()"
                
                  class="btn bg-primary fw-light text-white me-5 px-4"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </footer>
  </main>
</template>
<script>
import Multiselect from "@vueform/multiselect";
import { useDropzone } from "vue3-dropzone";

export default {
  components: {
    Multiselect,
  },
  setup() {
    const url = "/upload"; //
    const saveFiles = (files, vm) => {
      const formData = new FormData(); // pass data as a form
      for (var x = 0; x < files.length; x++) {
        formData.append("images", files[x]);
      }
      vm.$axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.info(response.data);
          if (response.data.success) {
            response.data.data.forEach((e) => {
              vm.form.images.push(e);
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };
    function onDrop(acceptFiles, rejectReasons) {
      console.log(acceptFiles);
      console.log(rejectReasons);

      let file_count = window.vm.form.images.length + acceptFiles.length;
      if (file_count > 5) {
        alert("5 Max file can be uploaded");
      } else if (rejectReasons.length == 0) saveFiles(acceptFiles, window.vm);
      else alert("5 Max file can be uploaded");
    }

    const { getRootProps, getInputProps, inputRef, ...rest } = useDropzone({
      onDrop,
      multiple: true,
      maxFiles: 5,
      noClick: false,
    });

    return {
      getRootProps,
      getInputProps,
      inputRef,
      ...rest,
    };
  },
  data() {
    return {
      id: 0,
      step: 1,
      projects: [],
      amenities: [],
      special_requirements: [],
      resp: "",
      form: {
        images: [],
      },
      toggle: [],
    };
  },
  mounted() {
    window.vm = this;
    this.getRetreatDetails();
  },
  methods: {
    deleteImage(i) {
      if (confirm("Are you sure you want to delete the photo ?"))
        this.form.images.splice(i, 1);

    },
    MarkAsCoverImage(i) {
      this.form.images.forEach((img, index) => {
        if (index == i) {
          img.isCoverImage = true;
        } else {
          img.isCoverImage = false;
        }
      });
      this.toggle[i] = false;
    },
    getRetreatDetails() {
      debugger;
      let retreat_id = this.$route.params.retreat_id;
      this.$axios.get("/farm-stay/retreat/" + retreat_id).then((resp) => {
        this.form = resp.data.data;
        let coverImage = resp.data.data.image;
        resp.data.data.images.forEach(img => {
              console.log(img.originalname)
              console.log(coverImage.originalname);
              if(img.originalname == coverImage.originalname ){
                img.isCoverImage = true;
              }else{
                img.isCoverImage = false;
              }
        })
         this.form.images = resp.data.data.images;
      });
    },
    saveButton() {
        
      this.$swal
          .fire({
            title: "Are you sure?",
            text: "Are you sure want to update the Farm Retreat",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              let retreat_id = this.$route.params.retreat_id;
              this.$axios
                .put("/farm-stay/retreat/" + retreat_id, this.form)
                .then((resp) => {
                  if (resp.data.success) {
                      this.$router.go(-1);
                  }
                });
            }
          });   },
  },
};
</script>